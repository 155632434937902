// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "StudioVsVandalism-module--CarrouselWrapper2--ogqWA";
export var DescriptionWrapper = "StudioVsVandalism-module--DescriptionWrapper--35Xa8";
export var DownloaderWrapper = "StudioVsVandalism-module--DownloaderWrapper--X+Z1r";
export var EventWrapper = "StudioVsVandalism-module--EventWrapper--DdX++";
export var ImageWrapper = "StudioVsVandalism-module--ImageWrapper--HdgIi";
export var ImagesWrapper = "StudioVsVandalism-module--ImagesWrapper--4AHBM";
export var MobileTile = "StudioVsVandalism-module--MobileTile--Ps6Wb";
export var PdpWrapper = "StudioVsVandalism-module--PdpWrapper--KYRiN";
export var PhotosWrapper = "StudioVsVandalism-module--PhotosWrapper--vpyfL";
export var TitleWrapper = "StudioVsVandalism-module--TitleWrapper--S3saS";
export var Wrapper = "StudioVsVandalism-module--Wrapper--vBXFx";