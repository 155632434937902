import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./StudioVsVandalism.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Tile from "./../../../../res/studiovsvandalism/studio-vs-vandalism-tile.png";
import InSitu1 from "./../../../../res/studiovsvandalism/insitu-1.jpg";
import InSitu2 from "./../../../../res/studiovsvandalism/insitu-2.jpg";
import InSitu3 from "./../../../../res/studiovsvandalism/insitu-3.jpeg";
import InSitu4 from "./../../../../res/studiovsvandalism/insitu-4.jpg";
import InSitu5 from "./../../../../res/studiovsvandalism/insitu-5.jpeg";

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: InSitu2, name: "InSitu2" },
    { src: InSitu3, name: "InSitu3" },
    { src: InSitu4, name: "InSitu4" },
  ],
};

const StudioVsVandalism = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="StudioVsVandalism"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>STUDIO VS VANDALISM<h2 style={{paddingLeft: "16px"}}></h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="studio-vs-vandalism"/>
        <br />
        <p>
        What happens when artists move from the street to the studio ? Is this work in the studio a continuation of their vandal interventions in the urban space ? How and why were they able to create a real artistic movement born in the street; a movement that from the 80's onwards is found in galleries and in museum institutions ? So many questions that leave one wondering but that can find some answers thanks to the exhibition Studio VS Vandalism, a display of works by about fifteen artists on the 3 floors of the gallery. Works from the 80's to today, with multiple supports and expressions.
  {/* Marseille, le 5 novembre 2020. La GHOST galerie et le Château de Forbin, deux lieux d’art contemporain à Marseille - dédiés au graffiti et au Post-graffiti des années 80 - accueilleront du 5 novembre au 5 décembre 2020 la première « double » exposition de l’artiste new-yorkais Chris DAZE Ellis retraçant ses 40 ans de création artistique. Une rétrospective riche de plus de 60 oeuvres qui relate l’évolution de son travail sur l’ensemble de sa carrière. */}
          </p>
          <br></br>
          <br />
        </div>
      </div>
        
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default StudioVsVandalism;
